<template>
    <div :class="(!homeEntry ? 'col-xs-12 col-md-6 col-lg-4' : 'home-entry') + ' weather-entry'">
        <table v-if="!homeEntry" class="table">
            <thead>
            <tr>
                <th>
                    {{ timeMoment.format($store.getters.tText('timeformat')) }}
                </th>
                <th>
                    <WeatherIcon :icon="weatherIcon" size="32" :type="type" />
                </th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>{{ $store.getters.tText('weather') }}</td>
                <td>{{ weatherCaption }}</td>
            </tr>
            <tr>
                <td>{{ $store.getters.tText('temperature') }}</td>
                <td>{{ Math.round( eW.main.temp ) }} °C</td>
            </tr>
            <tr v-if="showWind">
                <td>{{ $store.getters.tText('winddirection') }}</td>
                <td>
                    <svg viewBox="0 0 512 512" class="wind-direction">
                        <path :transform="'rotate('+ eW.wind.deg +',256,256)'" d="M 227.5205,59.825294 C 224.26303,63.082873 221.557,67.039723 219.63549,71.712899 L 92.361,417.17541 C 77.818918,457.17597 126.90839,488.08602 155.99825,458.99521 L 256.00206,358.98812 356.00589,458.99521 C 373.0664,476.05629 397.01706,472.48034 410.96222,458.53471 420.79717,448.69945 425.6578,433.71913 419.64313,417.17541 L 292.36864,71.701529 C 281.55588,45.458976 245.84875,41.49644 227.5205,59.825294 Z M 268.24174,80.59307 395.47644,425.9703 C 397.54576,431.65543 396.63617,436.49916 392.77609,440.35936 389.47882,443.65674 381.35504,447.99449 374.18633,440.82554 L 255.99638,322.63172 137.8178,440.81418 C 131.30854,447.32365 124.47524,444.11724 121.83174,442.44012 118.40372,440.26271 113.1622,435.21432 116.4879,426.06695 L 243.4156,81.497006 C 243.98409,80.121204 244.73451,78.984179 245.71232,78.006336 248.79356,74.924997 254.31932,73.549196 259.46419,74.578204 262.57954,75.203568 266.70112,76.903422 268.24174,80.59307 Z"></path>
                    </svg>
                </td>
            </tr>
            <tr v-if="showWind">
                <td>{{ $store.getters.tText('windspeed') }}</td>
                <td>{{ convertNumber(eW.wind.speed) }} m/s</td>
            </tr>
            </tbody>
        </table>
        <span v-if="homeEntry">
            <span class="loctime">
                {{ $store.getters.eventData.weather.cityName }} {{ $store.getters.tText('forecast') }}
            </span>
            <span class="wicon">
                <WeatherIcon :icon="weatherIcon" :type="type"/>
            </span>
            <span class="windicon">
                {{ Math.round( eW.main.temp ) }} °C
                <br>
                <svg viewBox="0 0 512 512" class="wind-direction" v-if="showWind">
                    <path :transform="'rotate('+ eW.wind.deg +',256,256)'" d="M 227.5205,59.825294 C 224.26303,63.082873 221.557,67.039723 219.63549,71.712899 L 92.361,417.17541 C 77.818918,457.17597 126.90839,488.08602 155.99825,458.99521 L 256.00206,358.98812 356.00589,458.99521 C 373.0664,476.05629 397.01706,472.48034 410.96222,458.53471 420.79717,448.69945 425.6578,433.71913 419.64313,417.17541 L 292.36864,71.701529 C 281.55588,45.458976 245.84875,41.49644 227.5205,59.825294 Z M 268.24174,80.59307 395.47644,425.9703 C 397.54576,431.65543 396.63617,436.49916 392.77609,440.35936 389.47882,443.65674 381.35504,447.99449 374.18633,440.82554 L 255.99638,322.63172 137.8178,440.81418 C 131.30854,447.32365 124.47524,444.11724 121.83174,442.44012 118.40372,440.26271 113.1622,435.21432 116.4879,426.06695 L 243.4156,81.497006 C 243.98409,80.121204 244.73451,78.984179 245.71232,78.006336 248.79356,74.924997 254.31932,73.549196 259.46419,74.578204 262.57954,75.203568 266.70112,76.903422 268.24174,80.59307 Z"></path>
                </svg>
                <br>
                <span v-if="showWind">{{ convertNumber(eW.wind.speed) }} m/s</span>
            </span>
            
        </span>
    </div>
</template>

<script>
import WeatherIcon from '@/components/WeatherIcon.vue'
import weatherOpts from '@/weatherOpts'
import moment from 'moment-timezone'
import _ from 'lodash'
    
export default {
    name: "WeatherEntry",
    components: { WeatherIcon },
    data() {
        return weatherOpts
    },
    props: ['eW', 'type'],
    computed: {
        homeEntry() {
            return this.type && this.type === 'home'
        },
        timeMoment() {
            return moment.utc(this.eW.dt_txt.replace(/ /,'T')).tz(this.$store.getters.eventData.timezone)
        },
        weatherIcon() {
            const tWeather = _.head(this.eW.weather)
            return tWeather.icon
        },
        weatherCaption() {
            const tWeather = _.head(this.eW.weather)
            
            if (this.$store.state.language === 'en') {
                return tWeather.description
            }
            
            switch (tWeather.description) {
                case 'clear sky':
                    return this.$store.getters.tText('clearsky')
                case 'overcast clouds':
                    return this.$store.getters.tText('overcast')
                case 'few clouds':
                    return this.$store.getters.tText('fewclouds')
                case 'scattered clouds':
                case 'broken clouds':
                    return this.$store.getters.tText('scatteredclouds')
                case 'moderate rain':
                    return this.$store.getters.tText('moderaterain')
                case 'light rain':
                    return this.$store.getters.tText('lightrain')
                case 'rain':
                    return this.$store.getters.tText('rain')
                case 'light snow':
                    return this.$store.getters.tText('lightsnow')
                case 'snow':
                    return this.$store.getters.tText('snow')
                case 'thunder':
                    return this.$store.getters.tText('thunder')
                
                default:
                    return tWeather.description
            }
        }
    },
    methods: {
        convertNumber(val) {
            if (this.$store.state.language === 'en') {
                return val + ''
            } else {
                let useVal = val + ''
                return useVal.replace(/\./,',')
            }
        }
    }
}
</script>

<style scoped lang="scss">
    .weather-entry {
        margin-bottom: 2em;
    }
    
    .table {
        width:100%;
        
        th {
            font-weight: inherit;
            
        }
        
        tr {
            border-bottom: 1px solid #c8cacb;
            
            td, th {
                padding-left:0;
                padding-right:0;
                text-align: right;

                &:first-child {
                    text-align: left;
                }
            }
            
            td {
                &:first-child {
                    color:#959899;
                }
            }
        }
        
        thead tr {
            border-top:0;
            th {
                border-top:0;
            }
            border-bottom:1px solid #191f22;
        }
        
        .wind-direction {
            height:1em;
            width:1em;
            
            path {
                opacity: 1;
                fill-opacity: 1;
                fill:#000;
            }
        }
    }
    
    .home-entry {
        display: block;
        
        span {
            display: inline-block;
        }
        
        .loctime {
            width:100%;
        }
        
        .wicon, .windicon {
            display: inline-block;
            width:50%;
        }
        
        .windicon {
            //padding-top:1rem;
        }
        
        svg {
            width:50%;
            max-width:32px;
            height:auto;
            vertical-align: top;
            
            path {
                opacity: 1;
                fill-opacity: 1;
                fill:var(--kachel-color);
            }
        }
        
    }
</style>